import React,{useState} from 'react';
import Icon from "../../Images/CheckCircleFilled.png";
import Icon2 from "../../Images/CancelFilled.png";
import { Button } from "@mui/material";
import InProgress from './SubmitPages/InProgress';

function ActionsPopUp({selectedComponentState,filteredTableData,tableData,itemValue,itemId,styli}){
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [openMyComponentsDialog, setOpenMyComponentsDialog] = useState(false);
    const [openLinksDialog, setOpenLinksDialog] = useState(false);
    const [openDropdownRow, setOpenDropdownRow] = useState(null);
    const [tableDataa, setTableData] = useState(tableData)
    
    const handleOpenMyComponentsDialog = (rowData) => {
        setSelectedRowData(rowData);
        if (rowData.ComponentState === "In Progress") {
            setOpenMyComponentsDialog(true);
        } else if (rowData.ComponentState === "Ready to Publish") {
            setOpenLinksDialog(true);
        }
        setOpenDropdownRow(null)
    };

    const handleRemoveRow = (rowId) => {
        console.log("Remove is clicked !!", rowId)
        // Fetch the data of the deleted row
        console.log("Data is ",filteredTableData)
        const deletedRow = filteredTableData.find((item) => item._id === rowId);
        // Send the deleted row data to the API
        fetch("https://prod.appstudioltts.com/api/request/community-request", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ComponentName: deletedRow.ComponentName,
                ComponentDetails: deletedRow.ComponentDetails,
                Category: deletedRow.Category,
                Technology: deletedRow.Technology,
                FlagValue: deletedRow.FlagValue
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Deleted row data sent successfully:", data);
            })
            .catch((error) => {
                console.error("Error sending deleted row data:", error);
            });

        // Remove the row from the table
        fetch(
            `https://prod.appstudioltts.com/api/linkstateupdate/removedata/${rowId}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => response.json())
            .then(() => {
                const updatedTableData = tableDataa.filter((item) => item._id !== rowId);
                setTableData(updatedTableData);
            })
            .catch((error) => {
                console.error("Error removing row:", error);
            });
        setOpenDropdownRow(null);        
    };
   
    return(
        <>
        <div className="dropDowndiv" style={styli} onBlur={() => setIsOpen(false)} tabIndex="0" autoFocus>
                            <Button
                                variant="contained"
                                onClick={() => { handleOpenMyComponentsDialog(itemValue) }}
                                style={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    width: "99px",
                                    borderRadius: 0,
                                    backgroundColor:
                                        selectedComponentState === "Ready to Review" ||
                                            selectedComponentState === "Rejected"
                                            ? "#e0e0e0"
                                            : "white",
                                    color:
                                        selectedComponentState === "Ready to Review" ||
                                            selectedComponentState === "Rejected"
                                            ? "#bababa"
                                            : "black",

                                    transition: "background-color 0.3s",
                                }}
                                onMouseOver={(e) => {
                                    if (
                                        !(
                                            selectedComponentState ===
                                            "Ready to Review" ||
                                            selectedComponentState === "Rejected"
                                        )
                                    ) {
                                        e.currentTarget.style.backgroundColor =
                                            "lightblue";
                                    }
                                }}
                                onMouseOut={(e) => {
                                    if (
                                        !(
                                            selectedComponentState ===
                                            "Ready to Review" ||
                                            selectedComponentState === "Rejected"
                                        )
                                    ) {
                                        e.currentTarget.style.backgroundColor = "white";
                                    }
                                }}
                                disabled={
                                    selectedComponentState === "Ready to Review" ||
                                    selectedComponentState === "Rejected"
                                } // Disable if component state is 'Ready to Review'
                            >
                                <img src={Icon} alt="Submit"
                                    style={{ marginRight: "10px",
                                        opacity:
                                            selectedComponentState ===
                                                "Ready to Review" ||
                                                selectedComponentState === "Rejected"
                                                ? 0.5
                                                : 1, }} />
                                Submit
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => { handleRemoveRow(itemValue._id)}}
                                className="RemoveButtonDropdown"
                                onMouseOver={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                    "lightblue")
                                }
                                onMouseOut={(e) =>
                                    (e.currentTarget.style.backgroundColor = "white")
                                }>
                                <img src={Icon2} alt="Remove" className="removeIconContribute" />
                                Remove
                            </Button>
                        </div>

                        {openMyComponentsDialog&& <InProgress MyComponentsDialog={openMyComponentsDialog}/> }           
        </>
    )
}
export default ActionsPopUp;
