import "./App.css";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { MsalProvider } from "@azure/msal-react";
import Routing from "./Components/Routing";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function App({ instance }) {
  return (
    <>
      <MsalProvider instance={instance}>
        <Routing />
      </MsalProvider>
      {/* <Certificate/> */}
    </>
  );
}

export default App;
