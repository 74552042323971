import React from "react";
import { Container, Row, Col } from "react-grid-system";
import Header from "./Header";
import Footer from "./Footer";
import "../Common.css";
import "../App.css";

function ApiHub() {
  return (
    <>
      <Header>
        <Container fluid>
          <Row>
            <Header />
          </Row>
        </Container>
      </Header>
      <Container fluid className="apicontainer">
        <iframe id="my-iframe" src="https://devxidp.com/dashboard-share" width="100%" height="100%" />
      </Container>
      <footer className="FooterClass">
        <Container fluid className="footerSize">
          <Row className="AppBar AppFooter">
            <Col sm={12} xs={12} md={12} lg={12}>
              <span className="spanFooterLT">
                © L&T Technology Services : All Rights Reserved.
              </span>
              <span className="Version">API Hub v1.0</span>
              <span>
                <Footer />
              </span>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default ApiHub;
