import React, { useState, useEffect } from "react";
import { PieChart } from "@ltts-dtp-appstudio/react-graphscharts";
// import piejson2 from "../piedata2.json";
import { Container, Row, Col } from "react-grid-system";

export default function ChartCardPage1() {
  const [piedataCategory, setPieDataCategory] = useState([]);

  useEffect(() => {
    fetch('https://prod.appstudioltts.com/api/linkstateupdate/count-category')
      .then(response => response.json())
      .then(apiData => {
          const transformedData = apiData.map(item => {
              return {
                  category: item.Category,
                  technology: item.Technology,
                  value: item.totalCount,
                  color: item.Category === "GenericUI" ? "#79A9D4" : item.Category === "Navigation" ?"#D2E2F0":item.Category === "Forms"?"#1F70B7":item.Category === "Graphs/Charts"?"#A5C6E2":item.Category === "MediaView"?"#1F70B7":item.Category==="Resting Display"?"#79A9D4":"#D2E2F0"
              };
          });
          setPieDataCategory(transformedData);
      })
      .catch(error => {
          console.error('There was an error!', error);
      });
  }, []);

  const piestyles = (window.screen.availHeight>=728 && window.screen.availHeight<1085) && (window.screen.availWidth>=1366 && window.screen.availWidth<1728 )? {
    height: "226px",
    labelposition: "right",
    pointstyle: "circle",
    backgroundColor: "white",
    size:'7'
  } : window.screen.availHeight>=1085 && window.screen.availWidth>=1728 ? {
    height: "345px",
    labelposition: "right",
    pointstyle: "circle",
    backgroundColor: "white",
    size:'7'
  }: window.screen.availHeight>=1024 && window.screen.availWidth<=768? {
    labelposition:'bottom',
    pointstyle:'circle',
    backgroundColor:'white',
    height:'264px',
    size:'7'
  } :  window.screen.availHeight>=1080 && window.screen.availWidth<=1920? {
    labelposition:'right',
    pointstyle:'circle',
    backgroundColor:'white',
    height:'390px',
    size:'7'
  }: {
    height: "226px",
    labelposition: "right",
    pointstyle: "circle",
    backgroundColor: "white",
    size:'7'
  }

  const backstyle = (window.screen.availHeight>=728 && window.screen.availHeight<1085) && (window.screen.availWidth>=1366 && window.screen.availWidth<1728 )? {
  } : window.screen.availHeight>=1024 && window.screen.availWidth<=768? {
    marginTop:'46px',
  }: {
    // width:'530px'
  }

  return (
    <>
    {/* <div className="chart-dimension01"> */}
        <Row className="chart-dimension01">
            <Col sm={12} xs={12} md={12} lg={12} xl={12} xxl={12} xxxl={12}>
              <div className="chartCardsDiv1">
                <p className="pTag">App Studio Component Count - Category wise</p>
                <div className="PieChartStyle">
                  <PieChart json={piedataCategory} graphstyle={piestyles} backdropstyle={backstyle} />
                </div>
              </div>
            </Col>
        </Row>
      {/* </div> */}
    </>
  );
}
