import React from "react";
import LoginIcon from "../Images/MicrosoftTeams-image (42).png";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "@mui/material/Button";
import { blue } from "@mui/material/colors";
import "../Common.css";

const LoginPage = () => {
  const { instance } = useMsal();

  const handleSignIn = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "create", // You can modify the prompt as needed
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="image-container" style={{ display: "grid", gridTemplateColumns: "1fr 1fr", alignItems: "center" }}>
      {/* Left Side: Image */}
      <div>
        <img src={LoginIcon} alt="Login Icon" style={{ maxWidth: "100%", height: "auto" }} />
      </div>

      {/* Right Side: Community Text and Login Button */}
      <div className="text-and-button" style={{ display: "grid", gap: "1rem" }}>
        <div>Welcome to the App Studio Community</div>

        <Button className="LoginPageButton" color="inherit" onClick={handleSignIn}>
          Login With Outlook Credentials
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
