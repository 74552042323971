import React, { useState, useEffect } from "react";
import { HorizontalBarGraph } from "@ltts-dtp-appstudio/react-graphscharts";
import { Container, Row, Col } from "react-grid-system";

export default function ChartCardPage3() {
  const [angularData, setAngularData] = useState([]);
  const [reactData, setReactData] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    fetch("https://prod.appstudioltts.com/api/angular")
      .then((response) => response.json())
      .then((data) => {
        setAngularData(data);
      })
      .catch((error) => {
        console.error("Error fetching Angular data:", error);
      });

    fetch("https://prod.appstudioltts.com/api/react")
      .then((response) => response.json())
      .then((data) => {
        setReactData(data);
      })
      .catch((error) => {
        console.error("Error fetching React data:", error);
      });
  }, []);

  useEffect(() => {
    if (angularData.length > 0 && reactData.length > 0) {
      const formattedData = angularData.map((angularItem) => {
        const correspondingReactItem = reactData.find(
          (reactItem) =>
            reactItem.Category.toLowerCase().replace("_", "") ===
            (angularItem.category
              ? angularItem.category.replace("_", "").toLowerCase().replace("/", "")
              : "")
        );

        return {
          label: angularItem.category,
          value: angularItem.count,
          value2: correspondingReactItem ? correspondingReactItem.count : 0,
          color1: "#1F70B7",
          color2: "#79A9D4",
        };
      });

      setChartData(formattedData);
    }
  }, [angularData, reactData]);

  const barstyles =
    (window.screen.availHeight >= 728 && window.screen.availHeight < 1085) &&
    (window.screen.availWidth >= 1366 && window.screen.availWidth < 1728)
      ? {
          height: "241px",
          marginLeft: "11px",
          backgroundColor: "white",
          size: "8",
        }
      : window.screen.availHeight >= 1085 && window.screen.availWidth >= 1728
      ? {
          height: "330px",
          backgroundColor: "white",
          size: "8",
        }
      : window.screen.availHeight >= 1024 && window.screen.availWidth <= 768
      ? {
          height: "241px",
          backgroundColor: "white",
          size: "8",
        }
      : window.screen.availHeight >= 1080 && window.screen.availWidth <= 1920
      ? {
          height: "390px",
          backgroundColor: "white",
          size: "8",
        }
      : {
          height: "241px",
          marginLeft: "11px",
          backgroundColor: "white",
          size: "8",
        };

  return (
    <>
      {/* <div className="chart-dimension1"> */}
        <Row className="chart-dimension1">
          <Col sm={12} xs={12} md={12} lg={12} xl={12} xxl={12} xxxl={12}>
            <div className="chartCardshorizontal">
              <p className="pTag2">
                Total Views of React and Angular Libraries in App Studio{" "}
              </p>
              {/* <div className="horizontalChartStyle" style={{ marginTop: "5px", width:"550px" }}> */}
              <div className="horizontalChartStyle" style={{ marginTop: "5px" }}>
                <HorizontalBarGraph json={chartData} graphstyle={barstyles} />
              </div>
            </div>
          </Col>
        </Row>
      {/* </div> */}
    </>
  );
}
