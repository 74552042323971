import React, { useState, useEffect, useRef } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useMsal } from "@azure/msal-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../Styling/addComponent.css";

function AddComponent({ open, handleClose, flagVal }) {
  const { instance } = useMsal();
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [projectName, setProjectName] = useState("");
  const [requestTechnology, setRequestTechnology] = useState("");
  const [showOption, setShowOption] = useState(false);
  const [category, setCategory] = useState("");
  const [othersCategoryName, setOthersCategory] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [employeeData, setEmployeeData] = useState(null); // State to store employee data
  const { register, clearErrors, handleSubmit, formState: { errors }, } = useForm();

  const chatsender = instance.getAllAccounts() && instance.getAllAccounts()[0] && instance.getAllAccounts()[0].name;
  const EmpMailDetail = instance.getAllAccounts() && instance.getAllAccounts()[0] && instance.getAllAccounts()[0].username;
  const ActiveUser = employeeData && employeeData.filter((i) => i["crisp status"] == "Active")
  const EmpDeliveryGroup = ActiveUser && ActiveUser[0] && ActiveUser[0].DeliveryGroup;
  const EmpDeliveryUnit = ActiveUser && ActiveUser[0] && ActiveUser[0].DeliveryUnit;
  const dialogRef = useRef(null);
  useEffect(() => {
    const userEmail = instance.getAllAccounts()[0]?.username;

    if (userEmail) {
      // Use the user's email to fetch employee data
      fetch(`https://prod.appstudioltts.com/api/empdata/employees/${userEmail}`)
        .then((response) => response.json())
        .then((data) => {
          // Store the fetched data in local storage
          localStorage.setItem("employeeData", JSON.stringify(data));
          setEmployeeData(data);
        })
        .catch((error) => {
          console.error("Error fetching employee data:", error);
        });
    }
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  const handleClose1 = () => {
    handleClose(false);
  };

  const submitData = async () => {
    // Call POST API to send name, details, and project name
    if (Array.isArray(employeeData) && employeeData.length > 0) {
      const requestData = {
        ComponentName: name,
        ComponentDetails: details,
        ProjectName: projectName,
        Technology: requestTechnology,
        Category: category,
        OtherCategory: othersCategoryName,
        CustomerName: customerName,
        SenderName: chatsender,
        SenderEmail: EmpMailDetail,
        DeliveryGroup: EmpDeliveryGroup,
        DeliveryUnit: EmpDeliveryUnit,
        FlagValue: flagVal

      };

      console.log("Request Data -----", requestData);

      fetch(
        "https://dev.appstudioltts.com/api/request",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(requestData),
        }
      ).then((response) => response.json());

      toast.success("Component details submitted successfully!", {
        theme: "colored",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
      handleClose1();
      setName("");
      setDetails("");
      setCategory("");
      setOthersCategory("");
      setRequestTechnology("");
      setProjectName("");
      setCustomerName("");
      setProjectName("");
      setCustomerName("");
    }
  };

  //To select category as other
  const onCategoryChange = (e) => {
    setCategory(e.target.value);
    if (e.target.value === "Others") setShowOption(true);
    else setShowOption(false);
  };

  return (
    <div className="RequestComponent_Wrap" >
      <Dialog open={open} onClose={handleClose1} aria-labelledby="form-dialog-title"  >
        <DialogTitle id="form-dialog-title req-heading">
          Add New Component
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 26, top: 20, color: (theme) => theme.palette.grey[500], }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {/* <DialogContent className="requestOuter"> */}
        <DialogContent
          className="requestOuter"
          onMouseDown={(event) => {
            if (!event.target.closest(".requestOuter")) {
              event.stopPropagation(); // Stop event propagation if click is outside dialog 
            }
          }}
        >
          <label className="reqLabel">Component Name</label>
          <span className="requiredSymbol">* </span>
          <TextField id="name" className="requestInput" {...register("name", { required: true })}
            placeholder="Enter Component Name..."
            type="text"
            fullWidth
            value={name}
            onClick={(event) => {
              const isInsideDialog = event.target.closest(".requestOuter") !== null;
              if (!isInsideDialog) {
                event.stopPropagation(); // Stop event propagation if click is outside dialog 
              } else {
                clearErrors("name");
              }
            }}
            onChange={(event) => setName(event.target.value)}
          />
          {errors.name && (
            <p className="error-required">Component name required</p>
          )}

          <label className="reqLabel">Component Details</label>
          <span className="requiredSymbol">*</span>
          <TextField
            id="details"
            className="requestInput"
            placeholder="Enter Component Details..."
            {...register("details", { required: true })}
            multiline
            rows={4}
            fullWidth
            value={details}
            onClick={(event) => {
              event.stopPropagation(); clearErrors("details")
            }}
            onChange={(event) => setDetails(event.target.value)}
          />
          {errors.details && (
            <p className="error-required">Component details required</p>
          )}

          <div>
            {/* technology changes here */}
            <label className="requiredFieldLabel reqLabel">
              Select Technology
            </label>
            <span style={{ color: "red", fontSize: "14px" }}>*</span>
            <FormControl className="requestFormControl">
              <select
                className="requiredSelectInput form-select"
                id="technology"
                {...register("tech", { required: true })}
                type="text"
                value={requestTechnology}
                onClick={() => clearErrors("tech")}
                onChange={(event) => setRequestTechnology(event.target.value)}
                placeholder="Select Technology">
                <option value="" disabled selected hidden>
                  Choose Technology...
                </option>
                <option value="Angular">Angular</option>
                <option value="React">React</option>
                <option value="Angular,React">Angular,React</option>
              </select>
            </FormControl>
            {errors.tech && (
              <p className="error-required">Technology required</p>
            )}
          </div>

          <div>
            <label className="requiredFieldLabel reqLabel">
              Select Category
            </label>
            <FormControl className="requestFormControl">
              <select
                className="requiredSelectInput form-select"
                id="category"
                type="text"
                value={category}
                onChange={onCategoryChange}
                placeholder="Select Category">
                <option value="" disabled selected hidden>
                  Choose Category...
                </option>
                <option value="Graphs/Charts">Graphs/Charts</option>
                <option value="Forms">Forms</option>
                <option value="GenericUI">Generic UI</option>
                <option value="MediaView">MediaView</option>
                <option value="Navigation">Navigation</option>
                <option value="Resting Display">Resting Display</option>
                <option value="Others">Others</option>
              </select>
              {showOption && (
                <div className="field other_category">
                  <div className="control m-t">
                    <TextField
                      id="otherCategory" className="requestInput"
                      placeholder="Enter Category Name..." type="text" fullWidth value={othersCategoryName} onChange={(event) => setOthersCategory(event.target.value)} />
                  </div>
                </div>
              )}
            </FormControl>
          </div>

          <div>
            <label className="reqLabel">Project Name</label>
            <TextField
              id="projectName" className="requestInput"
              placeholder="Enter Project Name..."
              type="text" fullWidth value={projectName}
              onChange={(event) => setProjectName(event.target.value)} />
          </div>
          <div>
            <label className="reqLabel">Customer Name</label>
            <TextField
              id="projectName" className="requestInput"
              placeholder="Enter Customer Name..."
              type="text" fullWidth value={customerName}
              onChange={(event) => setCustomerName(event.target.value)} />
          </div>
        </DialogContent>
        <DialogActions className="reqBtn-wrap" style={{ padding: "8px 25px 21px 8px" }} >
          <Button onClick={handleClose} color="primary" className="requestBtns requestcancel">
            Cancel
          </Button>
          <Button onClick={handleSubmit(submitData)} color="primary" className="requestBtns">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer hideProgressBar={true} />
    </div>
  );
}

export default AddComponent;