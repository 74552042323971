import React,{useState} from 'react'
import {Dialog,DialogTitle,DialogContent,DialogActions,} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {CheckCircleOutline, Email,HighlightOff, MoreVert,} from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { useMsal } from "@azure/msal-react";
import '../../../Styling/pages.css'

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
}));

function InProgress({MyComponentsDialog}) {
    const [openMyComponentsDialog, setOpenMyComponentsDialog] = useState(MyComponentsDialog);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const { instance } = useMsal();


    const handleCloseMyComponentsDialog = () => {
        setOpenMyComponentsDialog(false);
    };
    const [formData, setFormData] = useState({
        JsonFile: "",
        GitHubLink: "",
        OpenSrcLib: "",
        HostedURL: "",
        DesignDocs: "",
        HowToUseHURL: "",
        checkbox: false,
    });

    
    const handleUploadClick = () => {
        document.getElementById('JsonFile').click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setFormData({
                ...formData,
                JsonFile: reader.result,
            });
            setUploadSuccess(true);
        };

        if (file) {
            reader.readAsText(file);
        }
    };
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: !!checked,
        });
    };

    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const isURL = (input) => {
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlPattern.test(input);
    };

    const isFormValid = () => {
        return (
            formData.JsonFile !== "" &&
            formData.GitHubLink !== "" &&
            formData.HostedURL !== "" &&
            formData.OpenSrcLib !== "" &&
            formData.DesignDocs !== "" &&
            formData.HowToUseHURL !== "" &&
            formData.checkbox
        );
    };


    const isGitHubURL = (input) => {
        return input.toLowerCase().includes("github");
    };

    const handleSubmitLinks = async (e) => {
        e.preventDefault();
console.log("FormData : ",formData)
        if (!selectedRowData) {
            return;
        }

        const formDataToSend = {
            inputJson: formData.JsonFile,
            githubUrl: formData.GitHubLink,
            hostedUrl: formData.HostedURL,
            designDocuments: formData.DesignDocs,
            documentsOnHostedUrl: formData.HowToUseHURL,
            SonarQubeTestedUrl: formData.OpenSrcLib,
            Email: instance.getAllAccounts()[0]?.username,
            checkbox: formData.checkbox,

            //Additional data from the selected row
            ComponentName: selectedRowData.ComponentName,
            ComponentState: "Ready to Review",
            ComponentDetails: selectedRowData.ComponentDetails,
            Category: selectedRowData.Category,
            Technology: selectedRowData.Technology,
            SenderName: instance.getAllAccounts()[0]?.name,
        };

        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formDataToSend),
        };

        try {
            console.log("Sending request with data:", formDataToSend);

            const response = await fetch(
                `https://prod.appstudioltts.com/api/linkstateupdate/updatedata/${selectedRowData._id}`,
                requestOptions
            );

            if (response.ok) {
                const responseData = await response.json();
                console.log("Response data:", responseData);

                setFormData({
                    JsonFile: "",
                    GitHubLink: "",
                    OpenSrcLib: "",
                    HostedURL: "",
                    DesignDocs: "",
                    HowToUseHURL: "",
                    checkbox: false,
                });
                setSelectedRowData(null);

                const designDocsInput = document.getElementById("DesignDocs");
                const howToUseHURLInput = document.getElementById("HowToUseHURL");

                if (designDocsInput) {
                    designDocsInput.value = "";
                }
                if (howToUseHURLInput) {
                    howToUseHURLInput.value = "";
                }
                if (response.ok) {
                    toast.success("Form submitted successfully!", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 3000,
                    });
                } else {
                    throw new Error("Failed to submit the form", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 3000,
                    });
                }
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error("Failed to submit form", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
        }
    };


    return (
        <>
   <Dialog open={openMyComponentsDialog} onClose={handleCloseMyComponentsDialog}>
                <DialogTitle>
                    <div className="form">
                        <h3 className="heading">
                            {" "}
                            Select from the below listed Things
                        </h3>
                        <CloseButton aria-label="close" onClick={handleCloseMyComponentsDialog}>
                            <CloseIcon />
                        </CloseButton>

                        <div className="form-group">
                            <label htmlFor="GitHubLink">
                                Github URL for individual component{" "}
                                <span className="spanStyleStarCont">*</span>{" "}
                            </label>
                            <input
                                type="text"
                                id="GitHubLink"
                                name="GitHubLink"
                                value={formData.GitHubLink}
                                onChange={handleChangeForm}
                                placeholder="URL"
                                tabIndex='1'
                                style={{cursor:'pointer'}}
                                onClick={(e) => console.log("Focus event triggered")}
                            />

                            {formData.GitHubLink &&
                                (isURL(formData.GitHubLink) ? (
                                    isGitHubURL(formData.GitHubLink) ? (
                                        <CheckCircleOutline className="validation-icon valid" />
                                    ) : (
                                        <HighlightOff className="validation-icon invalid" />
                                    )
                                ) : (
                                    <HighlightOff className="validation-icon invalid " />
                                ))}
                        </div>

                        <div className="form-group">
                            <label htmlFor="HostedURL">
                                Hosted URL <span className="spanStyleStarCont">*</span>{" "}
                            </label>
                            <input
                                type="text"
                                id="HostedURL"
                                name="HostedURL"
                                value={formData.HostedURL}
                                onChange={handleChangeForm}
                                placeholder="URL"
                                tabIndex='1'
                                onFocus={(e) => console.log("Focus event triggered")}
                            />
                            {formData.HostedURL &&
                                (isURL(formData.HostedURL) ? (
                                    <CheckCircleOutline className="validation-icon valid" />
                                ) : (
                                    <HighlightOff className="validation-icon invalid" />
                                ))}
                        </div>

                        <div className="form-group">
                            <label htmlFor="DesignDocs">
                                Design Document <span className="spanStyleStarCont">*</span>{" "}
                            </label>
                            <input
                                type="text"
                                id="DesignDocs"
                                name="DesignDocs"
                                value={formData.DesignDocs}
                                onChange={handleChangeForm}
                                placeholder="Enter Design Document URL"
                            />

                            {formData.DesignDocs &&
                                (isURL(formData.DesignDocs) ? (
                                    <CheckCircleOutline className="validation-icon valid" />
                                ) : (
                                    <HighlightOff className="validation-icon invalid" />
                                ))}
                        </div>

                        <div className="form-group">
                            <label htmlFor="HowToUseHURL">
                                Document on how to use hosted URL{" "}
                                <span className="spanStyleStarCont">*</span>{" "}
                            </label>
                            <input
                                type="text"
                                id="HowToUseHURL"
                                name="HowToUseHURL"
                                value={formData.HowToUseHURL}
                                onChange={handleChangeForm}
                                placeholder="Enter Document URL"
                            />

                            {formData.HowToUseHURL &&
                                (isURL(formData.HowToUseHURL) ? (
                                    <CheckCircleOutline className="validation-icon valid" />
                                ) : (
                                    <HighlightOff className="validation-icon invalid" />
                                ))}
                        </div>

                        <div className="form-group">

                            <label htmlFor="JsonFile">
                                Input is a JSON file <span className="spanStyleStarCont">*</span>{" "}
                            </label>
                            <input type="file" id="JsonFile" name="JsonFile" accept=".json" onChange={handleFileChange} style={{ display: 'none' }} />
                            <button type="button" onClick={handleUploadClick}>Upload JSON File</button>
                            {uploadSuccess && <p className="success-message">File uploaded successfully!</p>}
                        </div>

                        <div className="form-group">

                            <label htmlFor="OpenSrcLib">
                                The Component provided above is SonarQube tested{" "}
                                <span className="spanStyleStarCont">*</span>{" "}
                            </label>
                            <input
                                type="text"
                                id="OpenSrcLib"
                                name="OpenSrcLib"
                                value={formData.OpenSrcLib}
                                onChange={handleChangeForm}
                                placeholder="Enter SonarQube tested URL"
                            />
                            {formData.OpenSrcLib &&
                                (isURL(formData.OpenSrcLib) ? (
                                    <CheckCircleOutline className="validation-icon valid" />
                                ) : (
                                    <HighlightOff className="validation-icon invalid" />
                                ))}
                        </div>

                        <div className="codeOpenSrcLib">
                            <label htmlFor="checkbox"> </label>
                            <input
                                type="checkbox"
                                id="checkbox"
                                name="checkbox"
                                checked={formData.checkbox}
                                onChange={handleCheckboxChange}
                            />
                            Code is using only open source libraries{" "}
                            <span className="spanStyleStarCont">*</span>{" "}
                        </div>
                        <Stack spacing={1} direction="row"></Stack>
                    </div>
                    <ToastContainer />
                </DialogTitle>
                <DialogContent>
                    {/* Your dialog content for My Components goes here */}
                </DialogContent>
                <DialogActions>
                    <Button
                        className="cancelBtnLinksPopup"
                        variant="outlined"
                        onClick={handleCloseMyComponentsDialog}>
                        Cancel
                    </Button>

                    <Button
                        className="submitBtnLinksPopup"
                        variant="contained"
                        onClick={handleSubmitLinks}
                        disabled={!isFormValid()} >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default InProgress;