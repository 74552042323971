import React from 'react';
import '../../Styling/leaderboard.css';

const RankTag = ({ rank }) => {
  return (
    <div className="rank-tag">
      <span className="rank-number"> {rank}</span>
    </div>
  );
};

export default RankTag;
