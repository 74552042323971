import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { useMsal } from "@azure/msal-react";
import Axios from "axios";
import "../Common.css";

const colors = {
  orange: "#FFC300",
  grey: "#a9a9a9",
};

const SendFeedbackPage = () => {
  const { instance } = useMsal();
  const stars = Array(5).fill(0);
  const [feedbackValue, setFeedbackValue] = useState(5);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [selectedOption, setSelectedOption] = useState("General");
  const [comments, setComments] = useState("");
  const [feedbackList, setFeedbackList] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    Axios.get("https://prod.appstudioltts.com/api/feedback/view")
      .then((response) => {
        setFeedbackList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching feedback data:", error);
      });
  }, []);

  const handleFeedbackSubmit = () => {
    const currentDate = new Date();

    const feedbackItem = {
      star_rate: feedbackValue,
      Category: selectedOption,
      message: comments,
      SenderName: instance.getAllAccounts()[0].name,
      createdAt: currentDate.toISOString(),
    };

    if (navigator.onLine) {
      // Submit feedback online
      Axios.post(
        "https://prod.appstudioltts.com/api/feedback/review",
        feedbackItem
      )
        .then((response) => {
          console.log("Feedback submitted:", response.data);
          setFeedbackList([...feedbackList, feedbackItem]);
          setFeedbackValue(0);
          setSelectedOption("General");
          setComments("");
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
          }, 3000);
        })
        .catch((error) => {
          console.error("Error submitting feedback:", error);
        });
    } else {
      // Save feedback offline
      const feedbackOffline = localStorage.getItem("feedbackOffline");
      const feedbackOfflineArray = feedbackOffline ? JSON.parse(feedbackOffline) : [];
      feedbackOfflineArray.push(feedbackItem);
      localStorage.setItem("feedbackOffline", JSON.stringify(feedbackOfflineArray));
      setFeedbackValue(0);
      setSelectedOption("General");
      setComments("");
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    }
  };

  useEffect(() => {
    // Check if there is any feedback saved offline and submit it
    const feedbackOffline = localStorage.getItem("feedbackOffline");
    if (feedbackOffline && navigator.onLine) {
      const feedbackOfflineArray = JSON.parse(feedbackOffline);
      feedbackOfflineArray.forEach((feedbackItem) => {
        Axios.post(
          "https://prod.appstudioltts.com/api/feedback/review",
          feedbackItem
        )
          .then((response) => {
            console.log("Feedback submitted:", response.data);
            setFeedbackList([...feedbackList, feedbackItem]);
          })
          .catch((error) => {
            console.error("Error submitting feedback:", error);
          });
      });
      localStorage.removeItem("feedbackOffline");
    }
  }, [navigator.onLine]);

  const handleClick = (value) => {
    setFeedbackValue(value);
  };

  const handleMouseOver = (value) => {
    setHoverValue(value);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleTextAreaChange = (event) => {
    setComments(event.target.value);
  };

  return (
    <div className="container">
      <div>
        <h3 className="h3StyleFeedback">
          We value your opinion and appreciate your feedback
        </h3>
        <h3>How would you rate the App Studio?</h3>
      </div>
      <div className="stars">
        {stars.map((_, index) => (
          <FaStar
            key={index}
            size={24}
            className="FastarFeedback"
            color={
              (hoverValue || feedbackValue) > index
                ? colors.orange
                : colors.grey
            }
            onClick={() => handleClick(index + 1)}
            onMouseOver={() => handleMouseOver(index + 1)}
            onMouseLeave={handleMouseLeave}
          />
        ))}
      </div>
      <div>
        <label>
          <h5>
            Please select the category that most closely matches your feedback:
          </h5>
          <div>
            <select
              className="selectStyleFeedback"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <option value="General">General</option>
              <option value="Graphs/charts">Graphs/charts</option>
              <option value="Forms">Forms</option>
              <option value="Generic UI">Generic UI</option>
              <option value="Media View">Media View</option>
              <option value="Navigation">Navigation</option>
              <option value="Resting Display">Resting Display</option>
              <option value="App Studio Playground">
                App Studio Playground
              </option>
              <option value="Onboarding Portal">Onboarding Portal</option>
            </select>
          </div>
        </label>
      </div>
      <textarea
        placeholder="Type your comments here..."
        className="textarea"
        value={comments}
        onChange={handleTextAreaChange}
      />
      <button className="button" onClick={handleFeedbackSubmit}>
        Submit
      </button>

      {showSuccess && (
        <div className="successMessage">Feedback Submitted Successfully!</div>
      )}

      <div>
        <h3 className="feedbackItem">User Comments:</h3>
      </div>

      {feedbackList
        .slice()
        .reverse()
        .map((feedbackItem, index) => (
          <div key={index} className="feedbackItem">
            <p>
              {Array.from({ length: feedbackItem.star_rate }).map((_, i) => (
                <FaStar key={i} size={20} style={{ color: colors.orange }} />
              ))}
            </p>
            <p>
              <strong>Category:</strong> {feedbackItem.Category}
            </p>
            <p className="pstyleSendFeedback">
              <strong>{`Comments: `}</strong> {feedbackItem.message}
            </p>
            <p>
              <strong>Sender Name:</strong> {feedbackItem.SenderName}
            </p>
            <p>
              <strong>Created At:</strong> {feedbackItem.createdAt}
            </p>
            <hr />
          </div>
        ))}
    </div>
  );
};

export default SendFeedbackPage;

