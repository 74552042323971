import React, { useState, useEffect } from "react";
import { PieChart } from "@ltts-dtp-appstudio/react-graphscharts";
// import piejson from "../piedata.json";
import { Container, Row, Col } from "react-grid-system";

export default function ChartCardPage2() {
  const [piedataCompo, setPieDataCompo] = useState([]);

  useEffect(() => {
    fetch('https://prod.appstudioltts.com/api/linkstateupdate/component-counts')
      .then(response => response.json())
      .then(apiData => {
          const transformedData = apiData.map(item => {
              return {
                  category: item.Technology,
                  technology: "",
                  value: item.count,
                  color: item.Technology === "React" ? "#79A9D4" : "#1F70B7"
              };
          });
          setPieDataCompo(transformedData);
      })
      .catch(error => {
          console.error('There was an error!', error);
      });
  }, []);

  const piestyles = (window.screen.availHeight>=728 && window.screen.availHeight<1085) && (window.screen.availWidth>=1366 && window.screen.availWidth<1728 )? {
    height: "226px",
    labelposition: "right",
    pointstyle: "circle",
    backgroundColor: "white",
    size:'7'
  } : window.screen.availHeight>=1085 && window.screen.availWidth>=1728 ? {
    height: "345px",
    labelposition: "right",
    pointstyle: "circle",
    backgroundColor: "white",
    size:'7'
  }: window.screen.availHeight>=1024 && window.screen.availWidth<=768? {
    labelposition:'bottom',
    pointstyle:'circle',
    backgroundColor:'white',
    height:'264px',
    size:'7'
  } : window.screen.availHeight>=1080 && window.screen.availWidth<=1920? {
    labelposition:'right',
    pointstyle:'circle',
    backgroundColor:'white',
    height:'390px',
    size:'7'
  }:  {
    height: "226px",
    labelposition: "right",
    pointstyle: "circle",
    backgroundColor: "white",
    size:'7'
  }

  return (
    <>
    {/* <Container fluid> */}
        <Row className="chart-dimension1">
            <Col sm={12} xs={12} md={12} lg={12} xl={12} xxl={12} xxxl={12} className="count-chart">
            <div className="chartCardsDiv2">
                <p className="pTag">
                  Count of Angular and React Components in App Studio
                </p>
                <div className="PieChartStyle">
                  <PieChart json={piedataCompo} graphstyle={piestyles} />
                </div>
              </div>
            </Col>
        </Row>
      {/* </Container> */}
    </>
  );
}
