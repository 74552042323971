import React, { useEffect, useState } from 'react'
import LeaderboardUsericon from "../../../Images/LeaderboardUserIcon.png";
import {Divider} from '@material-ui/core'

function UserRank({details}) {

    const sortedArray = details.sort((a,b)=>a.rank>b.rank);

    return (
        <>
        { sortedArray.slice(0,4).map((item)=>{
             const name = item.name.toLowerCase()
             const userName = name
             .split(' ')
             .map(word => {
               return word.charAt(0).toUpperCase() + word.slice(1);
             })
             .join(' ');
            return(
                <div className='userdetails'>
                <img src={LeaderboardUsericon} width='39px' height='39px' className='usericon' />
                <span className='usertext'>
                    <span style={{ fontWeight: 'bold', whiteSpace:'nowrap',overflow:'hidden', textOverflow:'ellipsis', width:'340px' }}>{userName}</span>
                    <span  style={{ whiteSpace:'nowrap',overflow:'hidden', textOverflow:'ellipsis', width:'340px' }}>Consumed Components</span>
                </span>
               <div> <Divider/></div>
            </div>
            )
        })}
        </>
    )
}

export default UserRank;